.exhibition {
  position: relative;
  padding-top: 3.5rem;

  @include media-tablet {
    padding-top: 5rem; }

  @include media-tablet-h {
    padding-bottom: 5rem; }

  .cont {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;

    @include media-tablet-h {
      flex-direction: row;
      justify-content: space-between; } }

  &-left {
    display: flex;
    flex-direction: column;
    row-gap: 1.25rem;
    max-width: 40.125rem;

    @include media-tablet {
      row-gap: 2.5rem; }

    @include media-tablet-h {
      max-width: none;
      width: 29.0625rem; }

    @include media-desktop {
      width: 34.25rem; }

    @include media-desktop-1600 {
      row-gap: 3.5rem;
      width: 40.125rem; }

    &__title {
      @include h36;
      max-width: 30.625rem;
      text-transform: uppercase;

      @include media-desktop {
        @include h48;
        max-width: none; } }

    &__subtitle {
      @include h18;
      text-transform: uppercase;

      @include media-tablet {
        @include h24; }

      @include media-desktop-1600 {
        @include h36; } }

    &__desc {
      display: flex;
      flex-direction: column;
      row-gap: 0.75rem;

      @include media-tablet {
        row-gap: 1.5rem; }

      ul {
        @include reset;
        @include p14;
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
        padding-left: 1.25rem;

        @include media-tablet {
          @include p18; }

        @include media-desktop-1600 {
          @include p24; } } }

    &__btn {
      display: none;

      @include media-tablet-h {
        display: inline-flex;
        width: 24.0625rem;
        text-transform: uppercase; } } }

  &-right {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 1.25rem;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    padding: 2.5rem 1.25rem;
    border-radius: 0 6.875rem 0 0;
    color: #fff;
    background-color: $red;

    @include media-tablet {
      row-gap: 0.75rem;
      margin-left: -1.875rem;
      margin-right: -1.875rem;
      padding: 2.5rem 1.875rem; }

    @include media-tablet-h {
      row-gap: 1.5rem;
      width: 31.6875rem;
      height: 28rem;
      margin-top: 2rem;
      margin-left: 0;
      padding: 5.5rem 1.875rem 5.5rem 5.625rem;
      border-radius: 0;
      background: url(@public/images/about-exhibition/bg-tablet-h.svg) no-repeat; }

    @include media-desktop {
      width: 38.6875rem;
      height: 24.5rem;
      margin-top: 5.3125rem;
      margin-right: -5rem;
      padding: 3.8125rem 4.6875rem 3.8125rem 9.5rem;
      background: url(@public/images/about-exhibition/bg-desktop.svg) no-repeat; }

    @include media-desktop-1440 {
      width: 46rem;
      height: 24.5rem;
      padding: 3.8125rem 5rem 3.8125rem 8.8125rem;
      background: url(@public/images/about-exhibition/bg-desktop-1440.svg) no-repeat; }

    @include media-desktop-1600 {
      width: 51.25rem;
      height: 35.3125rem;
      margin-top: 3.9375rem;
      padding: 6.1875rem 5rem 6.25rem 9.875rem;
      background: none; }

    @include media-desktop-1920 {
      width: 51.625rem;
      margin-top: 3.625rem;
      padding: 6.1875rem 5rem 5.9375rem 10.25rem; }

    &::after {
      @include media-desktop-1600 {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 61.625rem;
        height: 35.3125rem;
        background: url(@public/images/about-exhibition/bg-desktop-1920.svg) no-repeat;
        z-index: 1; } }

    &__title {
      @include h18;
      max-width: 38.625rem;
      text-transform: uppercase;
      z-index: 10;

      @include media-tablet {
        @include h24; }

      @include media-desktop-1600 {
        @include h36; } }

    &__desc {
      max-width: 38.625rem;
      z-index: 10;

      @include media-tablet {
        margin-bottom: 1.25rem; }

      ul {
        @include reset;
        @include p14;
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
        padding-left: 1.25rem;

        @include media-tablet {
          @include p18; }

        @include media-desktop-1600 {
          @include p24; } } }

    &__btn {
      @include fs18;
      width: 100%;
      text-transform: uppercase;

      @include media-tablet-h {
        display: none; } } } }
