.header--index {
  display: block;
  height: auto;
  z-index: 0;

  .header {
    &__main {
      @include media-desktop {
        display: grid;
        grid-template-columns: 1fr 45em;
        min-height: 0; }

      @include media-desktop-1920 {
        grid-template-columns: 2fr 1fr; }

      &-left {
        position: relative;
        display: none;

        @include media-desktop {
          display: block;
          height: 100%;
          overflow: hidden; } }

      &-slider {
        background: url(@public/images/header/preview.jpg) no-repeat center center;
        background-size: cover;

        @include media-tablet {
          height: 100%; }

        .swiper-container {
          height: 100%; } }

      &-slide {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center; } }

      &-right {
        position: relative;
        display: flex;
        flex-direction: column;

        @include media-tablet {
          display: grid;
          grid-template-areas: "logo desc" "title title";
          grid-template-columns: repeat(2, 1fr);
          background-color: transparent;
          border-bottom-left-radius: 1.25em; }

        @include media-desktop-1920 {
          min-width: 45em; } }

      &-logo {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-top: 1.9375em;
        padding-bottom: 1.9375em;
        background-color: $red;
        border-bottom-left-radius: 1.25em;
        border-bottom-right-radius: 1.25em;
        z-index: 3;

        @include media-tablet {
          position: relative;
          grid-area: logo;
          margin-top: 0;
          padding: 0; }

        img {
          display: block;
          width: 8.25em;
          height: 4.125em;

          @include media-tablet {
            width: auto;
            height: 100%; } } }

      &-desc {
        @include fs20;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        grid-area: desc;
        width: 100%;
        margin-top: -1em;
        padding: 2.875rem 2rem 1.625rem;
        background: $dark-grey;
        font-weight: 700;
        text-align: center;

        @include media-tablet {
          @include fs24;
          margin: 0;
          padding: 2.75rem 2.6875rem 2.75rem 2.375rem;
          z-index: 2;
          text-align: left;

          &:before {
            content: '';
            position: absolute;
            left: -2rem;
            width: 5rem;
            height: 100%;
            background: $dark-grey;
            z-index: 1; } }

        @include media-desktop {
          padding: 2.75rem 1.75rem; }

        @include media-desktop-1440 {
          padding: 3.8125rem 1.75rem; }

        @include media-desktop-1600 {
          padding: 4.5625rem 1.75rem; }

        @include media-desktop-1920 {
          padding: 6rem 1.75rem; }

        p {
          @include reset;
          display: block;
          margin-bottom: 0.5em;
          color: white;
          z-index: 10;

          &:last-child {
            margin-bottom: 0; } } }

      &-title-block {
        grid-area: title;
        padding: 1.75em 1em;
        max-width: 24.375em;
        margin: 0 auto;
        background: #fff;

        @include media-tablet {
          position: relative;
          display: flex;
          justify-content: center;
          flex-direction: column;
          max-width: none;
          width: initial;
          padding: 3.25em 0 4em;
          text-align: left;
          z-index: 2; }

        @include media-desktop {
          padding: 3.625em 0; }

        @include media-desktop-1440 {
          padding: 5.625em 0; }

        @include media-desktop-1600 {
          padding: 7em 0; }

        @include media-desktop-1920 {
          padding: 9.875em 0; } }

      &-date {
        @include fs18;
        margin-bottom: 0.5rem;
        font-weight: 800;
        color: $dark-grey;

        @include media-tablet {
          margin-bottom: 1.5rem;
          font-size: 2.125em;
          line-height: 1.25;
          font-weight: 800; } }

      &-title {
        color: $dark-grey;

        @include media-tablet {
          position: relative; }

        &-name {
          @include h48;
          font-weight: 800;
          display: inline-block;
          text-transform: uppercase;

          @include media-tablet {
            @include h96; }

          @include media-desktop-1600 {
            font-size: 4.75vw; }

          span {
            color: $red; } }

        &-reg {
          position: relative;

          @include media-tablet {
            display: inline-block;
            left: 0.2em;
            top: -0.24em; }

          .btn {
            width: 100%;
            text-align: center;
            font-size: 0.375em;
            margin-top: 0.1em;

            @include media-tablet {
              font-size: 0.2em;
              margin-top: 0em; } } } } } } }

.header--netw {
  .header {
    &__main {
      margin-top: 2.5em;
      padding-top: 1.5em;
      padding-bottom: 1.5em;
      border-bottom-left-radius: 0.5em;
      border-bottom-right-radius: 0.5em;

      @include media-tablet {
        margin-top: 3.625em;
        padding-top: 2.5em;
        padding-bottom: 2.5em;
        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em; }

      @include media-desktop {
        padding-top: 5em;
        padding-bottom: 5em; } }

    &__title {
      @include reset;
      @include h24;

      @include media-tablet {
        @include h36; }

      @include media-desktop {
        @include h64; } } } }
